<template>
  <div class="download-button">
    <b-button @click="addToPackShotDownloadList" variant="link">{{$T('buttontext')}}</b-button>
  </div>
</template>

<script>

export default {
  name: 'PackShotAddDownloadListButton',
  methods: {
    addToPackShotDownloadList () {
      this.$store.dispatch('search/searchByFiltersAll').then(response => {
        if (response.data.result) {
          this.$store.dispatch('packshotlistcart/addManyItems', { productIds: response.data.result.map(itm => itm.id) })
        }
      })
    }
  }
}
</script>

<style lang="scss">

</style>
